<template>
  <popup>
    <div class="error--popup">
      <h4 class="error--popup__title fw-bold">
        {{ $t(msgKey + ".title") }}
      </h4>

      <p class="error--popup__desc body-large">
        {{ $t(msgKey + ".content") }}
      </p>
      <div class="erro--msg body-large"><slot></slot></div>
      <general-btn type="regular" @click="handleClose">
        <h3>{{ $t(msgKey + ".btnText") }}</h3>
      </general-btn>
    </div>
  </popup>
</template>

<script>
import { popup, generalBtn } from "~/components/common/";

export default {
  name: "errorPopup",
  props: { msgKey: { type: String, default: "popup.generalError" } },
  components: { popup, generalBtn },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "~/assets/style/include/_commonInclude.scss";

.error--popup {
  padding-top: rem(24);
  word-break: break-all;

  &__title {
    margin-bottom: rem(8);
  }

  &__desc {
    margin-bottom: rem(32);
  }

  pre {
    white-space: break-spaces;
  }

  .general--btn {
    width: rem(259);
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
